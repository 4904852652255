import React from 'react';
import { ListGroup, Container, Row, Col, Button, Navbar, Nav } from 'react-bootstrap';
import { GeoAlt, Building, Briefcase } from 'react-bootstrap-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Make sure to create a corresponding CSS file for custom styles
import Footer from './footer/footer';
import LogoWithTitle from './components/logoWithTItle/logoWithTitle';


const CITY_LIST = [
  "Toronto",
  "Vancouver",
  "New York",
  "Atlanta",
  "Nashville",
  "Los Angeles",
  "San Francisco",
  "Miami",
  "Las Vegas"
];

const startAdventureClick = () => {
  window.gtag('event', 'click', {
    'event_category': 'Button Clicks',
    'event_label': 'start-your-adventure', 
    'value': 1 
  });
  console.log("Click!")
}

const appStoreClick = () => {
  window.gtag('event', 'click', {
    'event_category': 'Button Clicks',
    'event_label': 'app-store', 
    'value': 1 
  });
  console.log("Click!")
}

const playStoreClick = () => {
  window.gtag('event', 'click', {
    'event_category': 'Button Clicks',
    'event_label': 'play-store', 
    'value': 1 
  });
  console.log("Click!")
}

const startPackingClick = () => {
  window.gtag('event', 'click', {
    'event_category': 'Button Clicks',
    'event_label': 'start-packing', 
    'value': 1 
  });
  console.log("Click!")
}

const cityClick = (city) => {
  window.gtag('event', 'click', {
    'event_category': 'Button Clicks',
    'event_label': 'want-to-visit-' + city, 
    'value': 1 
  });
  console.log("City click!")
}

function App() {

  const [city, setCity] = React.useState(CITY_LIST[0]);

  // Change city every 3 seconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = CITY_LIST.indexOf(city);
      const nextIndex = (currentIndex + 1) % CITY_LIST.length;
      setCity(CITY_LIST[nextIndex]);
    }, 2000);
    return () => clearInterval(interval);
  }, [city]);

  return (
    <Container fluid className="p-0 app-container">
      <Navbar bg="light" expand="lg" className="app-navbar">
        <Container>
          <Navbar.Brand href="#home">
            <LogoWithTitle />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic- -nav">
            <Nav className="me-auto">
              {/* Add Nav.Link items here if needed */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container id="main-part" className="text-center my-4">
        <h1>Ignite your Wanderlust</h1>
        <p>Book a weekend trip in seconds</p>
        <p>Skip sifting through hundreds of flights and hotels.</p>
        <Button onClick={startAdventureClick} variant="warning" size="lg" className="my-3">
          Start your adventure with us
        </Button>
        <Row>
          <Col>
            <img
              src="/plane.png" // Replace with path to your image
              alt="Airplane in the sky"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>

      <Container id="choose-a-destination" className="app-section">
        <Row className="justify-content-center text-center">
          <Col>
            <h2>Choose a destination and go</h2>
            <p>Why should travelling be any more complicated than that?</p>
          </Col>
        </Row>
      </Container>

      <Container id="app-part" className="app-mockup-container text-center">
        <div className="phone-mockup">
          <div className="app-mockup-content">
            <img
              src="/iphone-app-mockup.png" // Replace with path to your image
              alt="iPhone app mockup"
              className="img-fluid"
            />

            <div className="app-store-cta-container">
              <img
                src="/assets/cta_app_store.svg"
                alt="Download on app store"
                className="img-fluid app-store-cta"
              />
              <img
                src="/assets/cta_app_store.svg"
                alt="Download on app store"
                className="img-fluid app-store-cta"
              />
            </div>
          </div>
        </div>
      </Container>


      <Container id="part-explore" className="app-section">
        <Row className="justify-content-center text-center">
          <h2>Explore</h2>
          <Button onClick={() => cityClick(city)} className="city-cta">{city}</Button>

        </Row>
        <Row className="justify-content-center text-center">
          <p className="quick-trips-message">Be on your way in a matter of days. Be back before Monday.</p>
        </Row>
      </Container>

      <Container className="part-how-to text-center">
        <Col>
          <h2>How to use Eleventh Hour to book your next weekend getaway</h2>

        </Col>

        <Col>
          <ListGroup variant="flush" className="my-3">
            <ListGroup.Item className='list-item'>
              <GeoAlt />
              <p>Select where you want to go this weekend</p>
            </ListGroup.Item>
            <ListGroup.Item className='list-item'><Building /> Have the app automatically tailor a stay for you</ListGroup.Item>
            <ListGroup.Item className='list-item'><Briefcase /> Pack your bags and go to the airport</ListGroup.Item>
          </ListGroup>
        </Col>

        <Col>
          <Button onClick={startPackingClick} id="cta" variant="primary" >Start Packing Now</Button>
        </Col>
      </Container>

      <Footer />
    </Container>


  );
}

export default App;
