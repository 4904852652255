import React from 'react';

import "./logoWithTitle.css"

function LogoWithTitle() {

    return (<div className='logo-with-title'>
        <img
            alt="11th Hour Logo"
            src="/logo.svg" // Replace with the path to your logo
            style={{ maxWidth: '100px' }} // Adjust size as needed
        />
        <div className='logo-title-container'>
            <h1 className='logoTitle'>11th</h1>
            <h1 className='logoTitle'>Hour</h1>
        </div>
    </div>)
}

export default LogoWithTitle;