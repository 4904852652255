import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import {  Instagram, Tiktok } from 'react-bootstrap-icons';

import LogoWithTitle from '../components/logoWithTItle/logoWithTitle';

import "./footer.css"

const Footer = () => {
  return (
    <Container fluid className='footer'>
      <Col xs={12} md={4} className="mb-3">
        <Row className='logo-row'>
          <LogoWithTitle />
          <Nav className="flex-column text-center mb-3">
            <Nav.Link href="#" style={{ color: 'white' }}>About Us</Nav.Link>
            <Nav.Link href="#" style={{ color: 'white' }}>Blog</Nav.Link>
            <Nav.Link href="#" style={{ color: 'white' }}>Privacy Policy</Nav.Link>
            <Nav.Link href="#" style={{ color: 'white' }}>Contact Us</Nav.Link>
          </Nav>
        </Row>
      </Col>


      <Col xs={12} md={4}>
        <Row>
          <Col xs={12} md={4} className="mb-3">
            <Tiktok className="me-4 footer-icon" />
            <Instagram className="me-4 footer-icon" />
            <img className="me-4 footer-icon"
              alt="11th Hour Logo"
              src="/assets/x.svg"
            />

            <i className="bi bi-twitter-x"></i>
          </Col>
        </Row>
      </Col>

      <Row className="mt-3 pb-4 justify-content-md-center">
        <Col xs={6} md={2} className="text-center mb-2">
          <img
            alt="Download on the App Store"
            src="/assets/cta_app_store.svg" 
           />
        </Col>
        <Col xs={6} md={2} className="text-center">
          <img
            alt="Get it on Google Play"
            src="/assets/cta_play_store.svg" 
            />
        </Col>
      </Row>

    </Container>
  );
};

export default Footer;
